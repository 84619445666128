import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import howItWork from '../../assets/images/how-its-work.png'

const WhyChooseUs = () => {
    return (
        <section className="how-its-work-area ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-content">
                            <span className="sub-title">
                                <img src={starIcon} alt="banner" /> 
                                We are part of your community
                            </span>
                            <h2>Why Choose Us?</h2>
                            <p>We live and work in your community. We are privileged to have the opportunity to assist you or a loved one live a better life.</p>
                            <div className="inner-box">
                                <div className="single-item">
                                    <div className="count-box">
                                        1
                                    </div>
                                    <h3>Experienced People</h3>
                                    <p>We take the time to assess and develop personalised treatment plans for home-based rehabilitation.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        2
                                    </div>
                                    <h3>Client Focused</h3>
                                    <p>We are devoted to our clients and their well-being</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        3
                                    </div>
                                    <h3>We Deliver</h3>
                                    <p>We have expertise in treating personal and motor vehicle accident injury, traumatic and acquired brain injury, spinal cord injury, orthopedic conditions and amputations, chronic pain, and emotional and mental distress.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        4
                                    </div>
                                    <h3>We Pay it Forward</h3>
                                    <p>We support not-for-profit organizations that provide education and programs that continue to help people to grow, recover and experience post-traumatic growth.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-image">
                            <img src={howItWork} alt="banner" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhyChooseUs